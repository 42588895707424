import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromFinancialStatements from '../reducers/financial-statements.reducer';
import { LoadingState } from '../../_shared/configs.model';

export const selectAccountsState = createFeatureSelector<fromFinancialStatements.State>(
  fromFinancialStatements.featureKey
);

export const selectBalanceSheet = createSelector(
  selectAccountsState,
  fromFinancialStatements.getBalanceSheet
);

export const selectGeneralLedger = createSelector(
  selectAccountsState,
  fromFinancialStatements.getGeneralLedger
);

export const selectProfitAndLoss = createSelector(
  selectAccountsState,
  fromFinancialStatements.getProfitAndLoss
);

export const selectStatementIsLoaded = createSelector(
  selectAccountsState,
  (state) => state.callState === LoadingState.LOADED
);

export const selectStatementLoading = createSelector(
  selectAccountsState,
  (state) => state.callState === LoadingState.LOADING
);

export const selectDownloadAccountsStatementInProgress = createSelector(
  selectAccountsState,
  fromFinancialStatements.getDownloadStatemenPdfInProgress
);
